/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Auth, Login } from "@cleeng/mediastore-sdk";
import "@adyen/adyen-web/dist/adyen.css";
import Offers from "./offers";
import { useRouter } from "next/router";

export default function Home() {
  const handleLoginSuccess = () => {
    window.gtag?.('config', process.env.NEXT_PUBLIC_GA_CONFIG, {
      'user_id': localStorage.CLEENG_CUSTOMER_ID
    });
    window.gtag?.('event', 'login', {
      method: 'Cleeng'
    });
    location.reload()
  }

  const router = useRouter()

  return (
    <div>
      {Auth.isLogged() ?
        <Offers /> :
        <div className="index-login-div">
          <Login
            onSuccess={() => handleLoginSuccess()}
            onRegisterClick={() => router.push('/register')}
            onPasswordResetClick={() => router.push('/forgotPassword')}
          />
        </div>
      }
    </div>
  );
}
